import React from "react";
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import {TailSpin} from "react-loader-spinner";

const Loading = () => {
  return(
    <div style={{display:"flex", justifyContent:'center', alignItems:'center'}}>
      <TailSpin
        color="#00BFFF"
        height={50}
        width={50}
        timeout={10000} //10 secs
      />
    </div>
  )
}

export default Loading