import { IRoute } from '../interfaces/routing';
import FormularioPago from '../pages/components/FormularioPago';

import NotFound from '../pages/sessions/404';
import InternalError from '../pages/sessions/500';
import SignIn from '../pages/sessions/Sign-in';

export const sessionRoutes: IRoute[] = [
  {
    path: 'page-404',
    component: NotFound
  },
  {
    path: 'page-500',
    component: InternalError
  },
  {
    path: 'sign-in',
    component: SignIn
  },
  {
    /******** PARA EL FORMULARIO, SE ENVIA EN LA URL EL ID_FACTU *******/
    path: 'pago/:id_factu', 
    component: FormularioPago
  }
];
